import './App.css';
import ComingSoonPage from "./pages/comingSoon/ComingSoonPage";
import React from "react";


function App() {
    return (
        <div className="App">
            <ComingSoonPage/>
        </div>
    );
}

export default App;
